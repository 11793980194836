import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import FaucetContextProvider from "./context/FaucetContex";
import LandingPage from "./pages/LandingPage";

const PageNotFound = () => {
  return (
    <div className=" bg-primary-black">
      <div className="layout flex flex-col items-center justify-center h-screen">
        <h1 className="text-white text-6xl lg:text-9xl font-semibold mb-5 lg:mb-10">
          404
        </h1>
        <h5 className="text-secondary-grey text-3xl lg:text-5xl mb-2 lg:mb-5 capitalize">
          Page not found
        </h5>
        <div className="mt-3 text-center">
          <p className="text-white text-sm lg:text-base">
            The Page you are looking for doesn't exist or an other error
            occured. Go to
            <Link className="text-primary-blue underline ml-2" to="/">
              Home Page
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <FaucetContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<LandingPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </FaucetContextProvider>
  );
}
