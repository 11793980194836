import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const FaucetContext = createContext();

const FaucetContextProvider = (props) => {
  const [faucet, setFaucet] = useState([]);
  const [currentFaucet, setCurrentFaucet] = useState(null);
  const [error, setError] = useState("");
  const [addAddress, setAddAddress] = useState("");

  useEffect(() => {
    axios
      .get("https://apifaucet.specta.app/v1/network")
      .then((response) => setFaucet(response.data))
      .catch((error) => console.log(error));
  }, []);

  const addFaucet = async (address, currency) => {
    try {
      const response = await axios.post(
        `https://apifaucet.specta.app/v1/address`,
        {
          address: address,
          currency: currency,
        }
      );
      setError("");
      setAddAddress(response.data);
    } catch (error) {
      setAddAddress("");
      setError(error.response.data);
    }
  };

  const values = {
    faucet,
    currentFaucet,
    setCurrentFaucet,
    error,
    addFaucet,
    addAddress,
  };

  return (
    <FaucetContext.Provider value={values}>
      {props.children}
    </FaucetContext.Provider>
  );
};

export default FaucetContextProvider;
