/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Footer() {
  return (
    <footer className="bottom-0">
      <div className="layout relative">
        <img
          src="/images/pattern.png"
          className="hidden md:block absolute left-[-80px] w-60 bottom-0"
          alt="pattern"
        />
        <div className="mx-0 md:mx-10 z-10">
          <div className="grid grid-cols-1  gap-3 sm:gap-6 md:gap-10 sm:grid-cols-3 md:grid-cols-4 mb-8">
            <div className="mb-6 md:mb-0">
              <a href="#">
                <img src={"/images/logo-lg.png"} className="w-48" alt="Logo" />
              </a>
              <p className="text-base text-white font-medium mt-5">
                Spectachain is True Blockchain running with POSA (Proof Of Stake Authority) consensus network which makes Security layer better compared to POA, POS and more eco-friendly than POW series.
              </p>
            </div>
            <div>
              <h2 className="mb-6 text-base font-medium  dark:text-white text-white">
                Useful Links
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li><a href="mailto:support@spectachain.io">Contact us</a></li>
                <li><a href="https://safe20.spectachain.io/" target="_blank">Create Smart Contract</a></li>
                <li><a href="https://faucet.spectachain.io/" target="_blank">Faucet</a></li>
                <li><a href="https://explorer.spectachain.io/" target="_blank">Explorer</a></li>
                <li><a href="https://nft.spectachain.io" target="_blank">Specta NFT</a></li>

              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-base font-medium  dark:text-white text-white">
                Community
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li><a href="https://spectachains.medium.com/" target="_blank">Blog</a></li>
                <li><a href="https://github.com/spectachain/spectachain" target="_blank">Github</a></li>
                <li><a href="https://docs.spectachain.io/whitepaper/" target="_blank">Gitbook</a></li>
                <li><a href="https://spectachains.medium.com/" target="_blank">Newsletters</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-500 pt-5 pb-12">
            <div className="flex flex-col-reverse md:flex-row justify-center md:justify-between items-center">
              <p className="text-xs font-medium text-center md:text-left text-white">
                Copyright &#169; {new Date().getFullYear()} Specta Blockchain.
                All Rights Reserved.
              </p>
              <div className="flex md:mb-0 mb-4">
                {/* <li><a href="https://t.me/spectachainsinfo" target="_blank"><i class="fab fa-telegram"></i></a></li>
                <li><a href="https://spectachains.medium.com/" target="_blank"><i class="fab fa-medium"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCPjQuX2gaZcGWYCRNARIOIQ" target="_blank"><i class="fab fa-youtube"></i></a></li> */}
                <a href="https://twitter.com/spectachains" target="_blank" className="mx-1">
                  <img
                    src="/images/twitter.svg"
                    className="w-6"
                    alt="twitter"
                  />
                </a>
                <a href="https://www.instagram.com/spectachains" target="_blank" className="mx-1">
                  <img
                    src="/images/instagram.svg"
                    className="w-6"
                    alt="instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
