/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const navLink = [
    { id: 1, name: "Home", url: "/" },
    { id: 2, name: "Wallet", url: "/wallet" },
    { id: 3, name: "Explorer", url: "https://testnet.spectachain.io" },
  ];

  return (
    <>
      <div
        onClick={() => setOpen(false)}
        className={`fixed z-30 inset-0 bg-dark-900/50  backdrop-blur lg:hidden transition-opacity duration-200 ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>
      <nav className="sticky z-20 bg-primary-black border-b-[0.5px] border-primary-grey top-0">
        <div className="layout flex flex-row justify-between items-center h-20">
          {/* Logo */}
          <div>
            <img
              src={"/images/logo-lg.png"}
              className="w-24 lg:w-32"
              alt="logo"
            />
          </div>
          <button
            type="button"
            onClick={handleOpen}
            className="inline-flex items-center p-2 ml-3 text-sm
           rounded-lg lg:hidden  focus:outline-none
             text-gray-400"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {open ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
          <div className="hidden w-full lg:block md:w-auto">
            <ul className="flex flex-col md:flex-row space-x-10 text-sm">
              {navLink.map(({ id, name, url }) => (
                <NavLink to={url} key={id}>
                  {({ isActive }) => (
                    <div
                      className={`${
                        isActive ? "text-primary-blue" : "text-white"
                      } font-medium hover:text-primary-blue`}
                    >
                      {name}
                    </div>
                  )}
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ width: 0 }}
            animate={{
              width: "70%",
              transition: {
                ease: "backIn",
                type: "spring",
              },
            }}
            exit={{
              width: 0,
              transition: { ease: "circIn" },
            }}
            className="fixed shadow-md z-50 top-0 right-0 bottom-0 h-screen lg:hidden bg-primary-black"
          >
            <div className="flex justify-between align-middle relative py-8 px-4 items-center">
              <div
                className="flex lg:hidden cursor-pointer text-xl rounded-full text-secondary-grey"
                onClick={() => setOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <ul className="flex flex-col justify-center space-y-7 p-5">
              {navLink.map(({ id, name, url }) => (
                <NavLink to={url} key={id}>
                  {({ isActive }) => (
                    <div
                      className={`${
                        isActive ? "text-primary-blue" : "text-white"
                      } font-medium hover:text-primary-blue`}
                    >
                      {name}
                    </div>
                  )}
                </NavLink>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
