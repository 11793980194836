import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import {
  HeroSection,
  TokenSection,
  FeatureSection,
  TutorialSection,
} from "../sections/HomeSection";

export default function LandingPage() {
  return (
    <>
      <div className="h-full min-h-screen bg-primary-black">
        <Navbar />
        <main>
          <div className="relative">
            <HeroSection />
            <div className="gradient gradient-01 z-0" />
            <TokenSection />
            <div className="gradient gradient-02 z-0" />
            <FeatureSection />
            <TutorialSection />
          </div>
        </main>
        <Footer />
      </div>
      <div className="gradient gradient-03 z-0" />
    </>
  );
}
