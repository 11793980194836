import React, { useState, Fragment, useContext, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FaucetContext } from "../../context/FaucetContex";

import Modal from "../../components/Modal";

export default function TokenSection() {
  const [showModal, setShowModal] = useState(false);
  const [pasteData, setPasteData] = useState("");
  const {
    faucet,
    addFaucet,
    error,
    addAddress,
    currentFaucet,
    setCurrentFaucet,
  } = useContext(FaucetContext);

  const dataFaucet = faucet?.data?.currency;
  const [selectedCoin, setSelectedCoin] = useState("");
  const activeCoin = dataFaucet?.find((data) => data.name == selectedCoin);

  const handleSubmit = (event) => {
    event.preventDefault();
    addFaucet(currentFaucet, activeCoin.symbol);
    setCurrentFaucet("");
  };

  const disableButton = false

  useEffect(() => {
    (addAddress.code == "200" || error) && setShowModal(true);
  }, [addAddress, error]);

  const paste = document.getElementById("paste");
  paste?.addEventListener("click", () => {
    navigator.clipboard
      .readText()
      .then((clipText) => (paste.innerText = clipText));
  });

  return (
    <section className="py-10 lg:py-20 lg:px-5 layout relative ">
      <img
        src="/images/pattern.png"
        className="hidden md:block absolute left-[-80px] w-60 top-1/3"
        alt="pattern"
      />
      <div className="flex flex-col md:flex-row gap-6 z-10">
        <div className="w-full lg:w-5/12 bg-gradient-to-b from-[#11101da4] to-[#2727275c]  rounded-lg p-6">
          <h3 className="text-2xl font-semibold mb-5 text-white">
            Get Test Tokens
          </h3>
          <p className="text-secondary-grey text-sm">
            This faucet transfers Test USDT and SPECTA Testnet
            Confirm details before submitting.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mt-8">
              <label
                htmlFor="countries"
                className="block mb-3.5 font-medium text-white"
              >
                Select Token
              </label>
              <Listbox value={selectedCoin} onChange={setSelectedCoin}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      className="relative w-full py-4 pl-3  pr-10 text-left bg-primary-black/50 rounded-md border border-primary-grey
                 cursor-pointer focus:outline-none sm:text-sm text-secondary-grey"
                    >
                      <span className="block truncate">
                        {selectedCoin ? (
                          <Fragment>
                            <div className="flex items-center">
                              <img
                                src={activeCoin?.icon}
                                className="w-5 h-5 object-cover rounded-full mr-4"
                                alt=""
                              />
                              {selectedCoin}
                            </div>
                          </Fragment>
                        ) : (
                          <span className="text-gray-500">
                            Select the token
                          </span>
                        )}
                      </span>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className={`w-4 h-4 text-gray-400 ease-in-out ${
                            open ? "rotate-180" : ""
                          }`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Listbox.Options className="absolute z-10 p-3 w-full space-y-1 mt-3 overflow-auto text-base bg-primary-black border border-primary-grey rounded-md shadow-md max-h-52 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {dataFaucet && dataFaucet.length > 0 ? (
                          dataFaucet.map((item, key) => (
                            <Listbox.Option
                              key={key}
                              value={item.name}
                              className={({ active, selected }) =>
                                `${
                                  active
                                    ? "text-white bg-neutral-900/80"
                                    : "text-secondary-grey"
                                }
        ${selected ? "text-white bg-neutral-900/80" : ""}
        select-none flex items-center rounded-lg relative p-3 gap-3 cursor-pointer`
                              }
                            >
                              {item.icon !== null ? (
                                <img
                                  src={item.icon}
                                  className="w-6 h-6 object-cover rounded-full"
                                  alt=""
                                />
                              ) : (
                                <div className="w-5 h-5 rounded-full bg-secondary-grey text-white text-xs  flex items-center justify-center p-3">
                                  NA
                                </div>
                              )}
                              {item.name}
                            </Listbox.Option>
                          ))
                        ) : (
                          <p className="text-secondary-grey text-sm text-center">
                            No data available
                          </p>
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            </div>
            <div className="mt-8">
              <label
                htmlFor="countries"
                className="block mb-3.5 font-medium text-white"
              >
                Wallet Address
              </label>
              <div className="relative cursor-pointer">
                <input
                  type="text"
                  id="paste"
                  value={pasteData}
                  onChange={(e) => {
                    setPasteData(e.target.value);
                    setCurrentFaucet(e.target.value);
                  }}
                  className="py-4 px-4 pr-16 overflow-auto bg-primary-black/50
                   block w-full border text-secondary-grey border-primary-grey
                    rounded-md text-sm focus:border-primary-blue"
                  placeholder="Input wallet address"
                />
                <div className="absolute inset-y-0 right-0 text-sm flex items-center pr-4">
                  <button
                    onClick={() =>
                      navigator.clipboard.readText().then(setPasteData)
                    }
                    type="button"
                    // id="paste"
                    className="bg-clip-text text-transparent bg-gradient-to-r
                                 from-[#A4EFF1] to-[#33BBCF] absolute right-3.5  bg-blue-700"
                  >
                    Paste
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-28">
              <button
                type="submit"
                disabled={disableButton}
                className={`${
                  disableButton
                    ? "opacity-70 cursor-not-allowed "
                    : "cursor-pointer opacity-100 "
                }bg-gradient-to-r from-[#A4EFF1] to-[#33BBCF]  focus:outline-none
                rounded-lg text-base w-full text-center py-4`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="w-full lg:w-7/12 bg-gradient-to-b from-[#11101da4] to-[#2727275c] rounded-lg p-6">
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border border-primary-grey rounded-lg shadow overflow-hidden">
                  <table className="min-w-full divide-y divide-primary-grey">
                    <thead>
                      <tr className="divide-x divide-primary-grey">
                        <th
                          scope="col"
                          className="px-6 w-6/12 py-5 text-center text-xs font-medium text-white uppercase"
                        >
                          Tokens
                        </th>
                        <th
                          scope="col"
                          className="px-6 w-6/12 py-5 text-center text-xs font-medium text-white uppercase"
                        >
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody className=" text-center divide-primary-grey">
                      {dataFaucet &&
                        dataFaucet.map((item, key) => (
                          <tr
                            key={key}
                            className="divide-x divide-primary-grey"
                          >
                            <td className="py-3 ml-5 flex items-center gap-3 whitespace-nowrap text-sm text-secondary-grey">
                              {item.icon !== null ? (
                                <img
                                  src={item.icon}
                                  className="w-6 h-6 object-cover"
                                  alt=""
                                />
                              ) : (
                                <div className="w-5 h-5 rounded-full bg-secondary-grey text-white text-xs  flex items-center justify-center p-3">
                                  NA
                                </div>
                              )}
                              <span>{item.name}</span>
                            </td>
                            <td className="py-3 whitespace-nowrap text-sm text-center px-4 text-secondary-grey">
                              {item.balance} {item.symbol}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col  items-center justify-center space-y-5">
          <div>
            {addAddress?.message ? (
              <svg
                width={56}
                height={56}
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x={4}
                  y={4}
                  width={48}
                  height={48}
                  rx={24}
                  fill="#D1FADF"
                />
                <path
                  d="M26.6 32.6L33.65 25.55L32.25 24.15L26.6 29.8L23.75 26.95L22.35 28.35L26.6 32.6ZM28 38C26.6167 38 25.3167 37.7373 24.1 37.212C22.8833 36.6867 21.825 35.9743 20.925 35.075C20.025 34.175 19.3127 33.1167 18.788 31.9C18.2633 30.6833 18.0007 29.3833 18 28C18 26.6167 18.2627 25.3167 18.788 24.1C19.3133 22.8833 20.0257 21.825 20.925 20.925C21.825 20.025 22.8833 19.3127 24.1 18.788C25.3167 18.2633 26.6167 18.0007 28 18C29.3833 18 30.6833 18.2627 31.9 18.788C33.1167 19.3133 34.175 20.0257 35.075 20.925C35.975 21.825 36.6877 22.8833 37.213 24.1C37.7383 25.3167 38.0007 26.6167 38 28C38 29.3833 37.7373 30.6833 37.212 31.9C36.6867 33.1167 35.9743 34.175 35.075 35.075C34.175 35.975 33.1167 36.6877 31.9 37.213C30.6833 37.7383 29.3833 38.0007 28 38ZM28 36C30.2333 36 32.125 35.225 33.675 33.675C35.225 32.125 36 30.2333 36 28C36 25.7667 35.225 23.875 33.675 22.325C32.125 20.775 30.2333 20 28 20C25.7667 20 23.875 20.775 22.325 22.325C20.775 23.875 20 25.7667 20 28C20 30.2333 20.775 32.125 22.325 33.675C23.875 35.225 25.7667 36 28 36Z"
                  fill="#61C454"
                />
                <rect
                  x={4}
                  y={4}
                  width={48}
                  height={48}
                  rx={24}
                  stroke="#ECFDF3"
                  strokeWidth={8}
                />
              </svg>
            ) : error ? (
              <svg
                width={56}
                height={56}
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x={4}
                  y={4}
                  width={48}
                  height={48}
                  rx={24}
                  fill="#FEE4E2"
                />
                <path
                  d="M30.8 20.6129L37.501 31.7739C38.464 33.3769 37.991 35.4859 36.444 36.4839C35.9248 36.8201 35.3196 36.9992 34.701 36.9999H21.298C19.477 36.9999 18 35.4699 18 33.5809C18 32.9419 18.173 32.3169 18.498 31.7739L25.2 20.6129C26.162 19.0099 28.196 18.5189 29.743 19.5169C30.171 19.7929 30.533 20.1679 30.8 20.6129ZM28.58 21.4519C28.4576 21.3731 28.3205 21.32 28.1769 21.2957C28.0334 21.2715 27.8865 21.2765 27.745 21.3107C27.6035 21.3448 27.4703 21.4072 27.3536 21.4942C27.2369 21.5812 27.1391 21.691 27.066 21.8169L20.365 32.9799C20.2561 33.1618 20.1988 33.3699 20.199 33.5819C20.199 34.2119 20.691 34.7219 21.299 34.7219H34.7C34.906 34.7219 35.107 34.6619 35.281 34.5499C35.5307 34.384 35.7067 34.1281 35.7725 33.8357C35.8383 33.5432 35.7886 33.2367 35.634 32.9799L28.933 21.8169C28.8451 21.6699 28.7247 21.545 28.581 21.4519H28.58ZM28 32.9999C27.7348 32.9999 27.4804 32.8945 27.2929 32.707C27.1054 32.5194 27 32.2651 27 31.9999C27 31.7346 27.1054 31.4803 27.2929 31.2928C27.4804 31.1052 27.7348 30.9999 28 30.9999C28.2652 30.9999 28.5196 31.1052 28.7071 31.2928C28.8946 31.4803 29 31.7346 29 31.9999C29 32.2651 28.8946 32.5194 28.7071 32.707C28.5196 32.8945 28.2652 32.9999 28 32.9999ZM28 23.9999C28.2652 23.9999 28.5196 24.1052 28.7071 24.2928C28.8946 24.4803 29 24.7346 29 24.9999V28.9999C29 29.2651 28.8946 29.5194 28.7071 29.707C28.5196 29.8945 28.2652 29.9999 28 29.9999C27.7348 29.9999 27.4804 29.8945 27.2929 29.707C27.1054 29.5194 27 29.2651 27 28.9999V24.9999C27 24.7346 27.1054 24.4803 27.2929 24.2928C27.4804 24.1052 27.7348 23.9999 28 23.9999Z"
                  fill="#FF4445"
                />
                <rect
                  x={4}
                  y={4}
                  width={48}
                  height={48}
                  rx={24}
                  stroke="#FEF3F2"
                  strokeWidth={8}
                />
              </svg>
            ) : (
              ""
            )}
          </div>
          <p className="text-white">
            {addAddress ? addAddress.message : error ? error.message : ""}
          </p>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="bg-gradient-to-r from-[#A4EFF1] to-[#33BBCF]  focus:outline-none
          font-semibold rounded-lg text-sm lg:text-base w-full lg:w-max text-center lg:px-28 py-4 flex justify-center items-center
          "
          >
            Close
          </button>
        </div>
      </Modal>
    </section>
  );
}
