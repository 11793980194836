import React from "react";

export default function TutorialSection() {
  const dataTutorial = [
    {
      id: 1,
      icon: "/images/token.png",
      title: "Highest Rates",
      desc: "Create a new account and login to get started.",
    },
    {
      id: 2,
      icon: "/images/wallet.png",
      title: "No Popups",
      desc: `Create a new account and login to get started.`,
    },
    {
      id: 3,
      icon: "/images/task.png",
      title: "Easy for development",
      desc: "Create a new account and login to get started.",
    },
  ];
  return (
    <section className="py-24">
      <div className="bg-cover bg-center bg-no-repeat py-20 bg-[url('/public/images/how-it-works-bg.svg')]">
        <div className="flex flex-col text-center items-center justify-center">
          <h1 className="text-3xl lg:text-4xl font-semibold mb-6">
            How Does it Works?
          </h1>
          <p className="text-white mx-10 lg:mx-20">
            We have worked hard to provide you with the best experience when
            compared to other similar sites! We are actively developing and
            improving our site, adding new and exciting features for you!
          </p>
          <div className="flex flex-col md:flex-row justify-between gap-10 lg:gap-20 mx-10 lg:mx-28">
            {dataTutorial.map(({ id, desc, title, icon }) => (
              <div
                key={id}
                className="text-center md:text-left space-y-2 lg:space-y-0 flex flex-col mt-10"
              >
                <div className="flex flex-col lg:flex-row items-center space-x-2 mb-3">
                  <img src={icon} alt="feature-1" />
                  <h2 className="text-xl font-semibold">{title}</h2>
                </div>
                <p className="text-white">{desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
