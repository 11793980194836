import React from "react";

export default function FeatureSection() {
  const dataFeature = [
    {
      id: 1,
      icon: "/images/highRate.png",
      title: "Highest Rates",
      desc: "We offer the highest rates around. Your rates will keep increasing as you use our site and level up!",
    },
    {
      id: 2,
      icon: "/images/noPop.png",
      title: "No Popups",
      desc: `We only show non-intrusive advertisements on our website, and don't display any annoying popups!`,
    },
    {
      id: 3,
      icon: "/images/easyDev.png",
      title: "Easy for development",
      desc: "the goal of easy development is to reduce the complexity and barriers to entry associated with software development",
    },
  ];

  return (
    <section className="py-24 layout relative">
      <img
        src="/images/pattern.png"
        className="hidden md:block absolute right-[-80px] w-60 top-1/2"
        alt="pattern"
      />
      <div className="z-10">
        <div className="mb-10 text-center lg:mx-10">
          <h1
            className="bg-clip-text font-semibold text-3xl lg:text-5xl mb-10 text-transparent 
                    bg-gradient-to-r from-[#A4EFF1] to-[#33BBCF]"
          >
            Fire Faucet’s Top Features
          </h1>
          <p className="text-secondary-grey ">
            We have worked hard to provide you with the best experience when
            compared to other similar sites! We are actively developing and
            improving our site, adding new and exciting features for you!
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-4 lg:mx-28">
          {dataFeature.map(({ id, desc, title, icon }) => (
            <div
              key={id}
              className="p-5 text-center flex flex-col items-center mt-10"
            >
              <img src={icon} alt="feature-1" className="mb-5" />
              <h2 className="mb-5 text-white text-xl">{title}</h2>
              <p className="text-secondary-grey">{desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
